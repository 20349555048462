import React from "react";
import styled from "styled-components";
import Finalnav from "../Homepages/Finalnav";
import { Link } from "react-router-dom";

// import img
import softwares1 from "../../components/img/softwares/1.png";
import softwares2 from "../../components/img/softwares/2.png";
import Footer from "../Homepages/Footer";

const Softwares = () => {
  return (
    <>
    <Finalnav/>
      <Software>
        <div className="container-fluid">
          <div className="row d-flex gap-5">
            <div className="col-12  d-flex justify-content-center">
              {/* doaGurcard start */}
              <div className="cardDoa mt-5">
                <div className="row p-5 h-100 d-flex align-items-center gap-3 gap-md-0">
                  <div className="col-md-8">
                    <div className="row d-flex gap-4">
                      <div className="col-12">
                        <h2>Softwares</h2>
                      </div>
                      <div className="col-12">
                        <p>
                          Your software needs to grow with your business.
                          DOAGuru InfoSystems builds future-proof software
                          solutions that are scalable and secure. We ensure your
                          software can adapt to your changing needs while
                          keeping your data safe and protected.
                        </p>
                      </div>
                      <div className="col-12">
                        <Link to="/contact_us" className="doaBtn border-0 text-white p-2 rounded-3">
                          Connect Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex justify-content-center">
                    <img
                      className="img-fluid rounded-1"
                      style={{ width: "210px" }}
                      src="https://img.freepik.com/free-vector/web-development-isometric-concept-composition-illustration_1284-55922.jpg"
                    />
                  </div>
                </div>
              </div>
              {/* doaGurcard end */}
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="bg-dark rounded-2"
                style={{
                  height: "7px",
                  width: "90px",
                }}
              ></div>
            </div>

            {/* main work  */}
            <div className="col-12 d-flex justify-content-center flex-wrap">
              <div className="SoftwareCom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">
                

                <div className="img d-flex bg-white flex-column">
                  <div
                    className="w-100  d-flex align-items-center"
                    style={{ height: "85%" }}
                  >
                    <img className="img-fluid" src={softwares1} />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "15%" }}
                  >
                    <h5>Dental Guru</h5>
                  </div>
                </div>

                <div className="img d-flex bg-white flex-column">
                  <div
                    className="w-100  d-flex align-items-center"
                    style={{ height: "85%" }}
                  >
                    <img className="img-fluid" src={softwares2} />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "15%" }}
                  >
                    <h5>BIGBULLES</h5>
                  </div>
                </div>

              </div>
            </div>
            {/* main work  */}
          </div>
        </div>
      </Software>
      <Footer />
    </>
  );
};

export default Softwares;

const Software = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 29px;
  background-color: #e2eafc;

  //  btn
  button,
  a {
    background-color: rgb(0, 43, 98);
    cursor: pointer;
    position: relative;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  button:hover,
  a:hover {
    transform: scale(0.9);
    background-color: green;
    color: white;
  }

  //cardDoa
  .cardDoa {
    width: 900px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.253);
    transition: all 0.4s;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .cardDoa:hover {
    transform: scale(0.9);
    background-color: white;
    color: black;
  }

  .img {
    width: clamp(300px, 45%, 400px);
    height: 290px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    box-shadow: 37px 38px 26px -24px rgba(0, 0, 0, 0.28);
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.245);
    background-color: white;
  }
  .img:hover {
    box-shadow: none;
    transform: scale(1.1);
    border: 1px solid rgba(0, 0, 0, 0.245);
  }

  .SoftwareCom {
    width: 1400px;
  }
`;
