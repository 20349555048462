import React from 'react';
import styled from "styled-components";

const Refund = () => {
  return (
    <>
    <Container>
        <div className="container">
        <h1 className="mb-4 mt-5 text-center">Refund & Cancelation Policy</h1>
        <p>Thank you for choosing DOA Guru InfoSystems. We are committed to providing quality products and services. Please review our return, refund, and cancellation policy below.</p>
        <br/>
        <p className='fs-5' style={{color:"black"}}><strong>Refund Policy:</strong></p>
        <p>There is no refund policy but if solution we provided is not working that particular module amount will be refunded within 45 days after informing to us.</p>
        <br />
        <p className='fs-5' style={{color:"black"}}><strong>Cancellation Policy:</strong></p>
        <p>Client needs to inform  us (Doa Guru InfoSystems or Doa Guru IT Solutions) one month in advance for stopping or cancelling the subscription and refund will be done with in 45 Days after informing to us.</p>
        <br />
        <p className='fs-5' style={{color:"black"}}><strong>Contact Us:</strong></p>
        <p>For any questions regarding returns, refunds, or cancellations, please contact us at doaguruinfosystems@gmail.com.</p>
        <p>We appreciate your business and look forward to providing you with exceptional IT solutions.</p>
        <p><span>[</span> Doa Guru InfoSystems or Doa Guru IT Solutions <span>]</span></p>
        </div>
    </Container>
    </>
  )
}

export default Refund;
const Container = styled.div`
p{
    color: #7d858c;
}
span{
  color: chocolate;
  font-size: 1.1rem;
  font-weight: 700;
}
`;