// Breadcrumb.js

import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const crumbs = [
    { label: "Home", url: "/" },
    ...pathnames.map((pathname, index) => ({
      label: pathname,
      url: `/${pathnames.slice(0, index + 1).join("/")}`,
    })),
  ];
  return (
    <Wrapper>
      <div className="breadcrumb mt-1 mx-4">
        {crumbs.map((crumb, index) => (
          <span key={index}>
            <a href={crumb.url}>{crumb.label}</a>
            {index < crumbs.length - 1 && (
              <span style={{ color: "blue" }}> &gt; </span>
            )}
          </span>
        ))}
      </div>
    </Wrapper>
  );
};

export default Breadcrumb;

const Wrapper = styled.div`
  a {
    text-decoration: none;
   
    color: black;
  }
`;
