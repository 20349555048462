import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";

const PortfolioLogin = ({ fachAuth }) => {
  let [dataAuth, setDataAuth] = useState({
    email: "",
    password: "",
  });

  let loginAuthHand = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDataAuth((pre) => {
      return { ...pre, [name]: value };
    });
  };

  let AuthApi = async () => {
    try {
      let data = await axios?.post(
        "https://doaguru.com/api/auth/login",
        dataAuth
      );
      let { token } = data?.data;
      console.log(token);

      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.setItem("token", "");
      }
    } catch (error) {
      console.log("err auth api not call");
    }
  };

  let subHand = (e) => {
    e.preventDefault();

    if (dataAuth.password.length > 4 && dataAuth.email.length > 4) {
      AuthApi();
      setTimeout(() => {
        fachAuth();
      }, 600);
    }
  };

  return (
    <>
      <LoginPort>
        <div className="LoginPort w-100">
          <form onSubmit={subHand}>
            <section className="vh-100 gradient-custom">
              <div className="container py-1 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div
                      className="card bg-dark text-white"
                      style={{ borderRadius: "0rem" }}
                    >
                      <div className="card-body p-5 text-center">
                        <div className="mb-md-5 mt-md-4 pb-5">
                          <h4 className="fw-bold mb-5">Portfolio Login</h4>

                          <div className="form-outline form-white mb-4">
                            <div className="w-100 d-flex justify-content-start">
                              <h6>Email</h6>
                            </div>
                            <input
                              onInput={loginAuthHand}
                              required
                              name="email"
                              type="email"
                              id="typeEmailX"
                              class="form-control form-control-sm"
                            />
                          </div>

                          <div class="form-outline form-white mb-4">
                            <div className="w-100 d-flex justify-content-start">
                              <h6>Password</h6>
                            </div>
                            <input
                              required
                              onInput={loginAuthHand}
                              name="password"
                              type="password"
                              id="typePasswordX"
                              class="form-control form-control-sm"
                            />
                          </div>

                          <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                            <button
                              class="btn btn-outline-light btn-lg px-5"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </LoginPort>
    </>
  );
};

export default PortfolioLogin;

let LoginPort = styled.div`
  .LoginPort {
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.568);
    height: 100vh;
    top: 0;
    backdrop-filter: blur(9px);
    border-radius: 8px;
  }
`;
