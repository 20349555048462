import React from "react";
import styled from "styled-components";
// import mobileapp from "../images/mobileapp.webp";
import android011 from "../images/android011.webp";
import ab from "../images/ab.webp";
import react1 from "../images/react1.webp";
import flutter1 from "../images/flutter1.webp";
import google from "../images/google.webp";
import ab2 from "../images/ab2.webp";
import angular1 from "../images/angular1.webp";
import node1 from "../images/node1.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import Footer from "../../page/Homepages/Footer";
import bgmoblie from "../images/bgmoblie.webp";
import mobliehero from "../images/mobliehero.webp";
import moblieneed1 from "../images/mobileneed1.webp";
import moblieneed2 from "../images/moblieneed2.webp";
import moblieneed3 from "../images/moblieneed3.webp";
import moblieneed4 from "../images/moblieneed4.jpeg";
import moblienative from "../images/mobilenative.webp";
import mobliewebapp from "../images/mobilewebapp.webp";
import mobliecross from "../images/mobliecross.webp";
import moblieprogress from "../images/moblieprogressive.webp";
import mobliehybrid from "../images/mobliehybrid.webp";
import moblierapid from "../images/moblierapid.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
function Mobiledevolopment() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>Best Mobile app development company-DOA Guru</title>
        <meta
          name="description"
          content=" Elevate Your Mobile Experience with DOA Guru, the Best Mobile App Development Company. We Transfer Ideas into Exceptional Mobile Apps for Success
          "
        />
        <link rel="canonical" href="https://doaguru.com/mobile-development" />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgmoblie})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                Best Mobile app development company
              </h1>

              <h5 className="hdd text-white">
                "Transforming Concepts into Interactive Engagements"
              </h5>
              <p className="pdd text-white mt-4">
                The proliferation of mobile applications constitutes a pivotal
                driver of innovation, connectivity, and convenience.As the
                demand for seamless app experiences continues to surge, finding
                the best mobile app development company becomes crucial. These
                companies play a pivotal role in revolutionizing the way we
                interact with technology and the outside world due to the
                widespread use of smartphones.
              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="mob">
              <img
                src={mobliehero}
                className="img3 d-block w-75"
                alt="..."
                id="mobhero"
              />
            </div>
          </div>
        </div>
        <div className="container  " id="mbapp">
          <h2 className="wwwwww text-center mt-4">
            Why Mobile App Development Is Essential
          </h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 30,
            }}
          ></div>
          <p>
            {" "}
            Delve into the paramount significance of mobile app development
            services within today's digital milieu. Unearth how bespoke
            applications, dispensed by the merger of the best digital marketing
            company and the best IT Company, elevate user engagement, augment
            user experiences, and unlock the full potential of businesses.
            Discern why investing in mobile app development services constitutes
            a strategic necessity for maintaining competitiveness in a
            mobile-centric universe."
          </p>
          <div className="row">
            <div className="col-sm-6" id="mobile">
              <div data-aos="fade-left">
                <img
                  id="clientpages"
                  src={moblieneed1}
                  className="img-fluid"
                  style={{
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                    marginTop: "1rem",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6" id="smd">
              <div data-aos="fade-right">
                <h3 className="mm">Elevated User Engagement</h3>
                <p className="text-justify mt-3">
                  {" "}
                  As a preeminent mobile app development company, we recognize
                  that successful applications hinge on the seamless and
                  delightful user experience they offer. In the contemporary
                  whirlwind of existence, users anticipate interfaces that are
                  user-friendly, navigation that flows seamlessly, and
                  interactions that captivate. Each tap and swipe within our app
                  development journey is meticulously crafted to evoke a sense
                  of naturalness and effortlessness, a testament to our
                  unwavering commitment to user-centric design principles.
                </p>
              </div>
              <br />
            </div>
            <div className="col-sm-6" id="desktop">
              <div data-aos="fade-left">
                <img
                  id="clientpages"
                  src={moblieneed1}
                  className="img-fluid"
                  style={{
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                    marginTop: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container  " id="mbapp">
          <div className="row">
            <div className="col-sm-6">
              <div data-aos="fade-right">
                <img
                  src={moblieneed2}
                  className="img-fluid"
                  style={{
                    marginTop: "1em",
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div data-aos="fade-left">
                <h3 className="mm1 ">Digital Transformation:</h3>
                <p className="text-justify mt-3">
                  {" "}
                  In this swiftly evolving landscape, staying at the forefront
                  is pivotal within the realm of digital transformation. Our app
                  development services, harmoniously fused with your vision, can
                  serve as catalysts for change, empowering your organization to
                  embrace novel technologies and optimize operations. Our apps
                  are meticulously tailored to align with your objectives, given
                  our profound appreciation for the strategic value of
                  technology integration.
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>

        <div className="container" id="mbapp">
          <div className="row">
            <div className="col-sm-6" id="mobile">
              <div data-aos="fade-left">
                <img
                  id="clientpages"
                  src={moblieneed3}
                  className="img-fluid"
                  style={{
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6" id="smd">
              <div data-aos="fade-right">
                <h3 style={{ marginBottom: "1rem" }} className="mm ">
                  Enhanced Efficiency and Productivity
                </h3>
                <p className="text-justify mt-3">
                  Our app development services are meticulously designed to
                  economize both time and resources. We engineer applications
                  that enhance efficiency and productivity across diverse
                  industries by streamlining workflows, automating tasks, and
                  furnishing real-time insights. Empower your workforce with the
                  tools they need to accomplish more in less time, courtesy of
                  our tailored app solutions.
                </p>
              </div>
              <br />
            </div>
            <div className="col-sm-6" id="desktop">
              <div data-aos="fade-left">
                <img
                  id="clientpages"
                  src={moblieneed3}
                  className="img-fluid"
                  style={{
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container  " id="mbapp">
          <div className="row">
            <div className="col-sm-6">
              <div data-aos="fade-right">
                <img
                  src={moblieneed4}
                  className="img-fluid"
                  style={{
                    marginTop: "1em",
                    width: "33rem",
                    height: "16rem",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div data-aos="fade-left">
                <h3 className="mm1 ">Nurturing Brand Identity</h3>
                <p className="text-justify mt-3">
                  {" "}
                  Our apps contribute substantially to fortifying the identity
                  of your brand, a pivotal ingredient for its triumph. As the
                  premier digital marketing entity and a foremost website
                  development establishment, we ensure that your app seamlessly
                  extends the core values of your brand. Our apps reinforce your
                  brand's identity and messaging through cohesive aesthetics,
                  fluid interactions, and an indelible user journey.
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="container">
          <h2 className="oo text-center mt-4">Our approach</h2>{" "}
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 30,
            }}
          ></div>
          <div className="row g-3">
            <div className="col-md-4 mt-2">
              <div className="card" id="card-design">
                <img
                  src={moblienative}
                  className="m-auto mt-3"
                  style={{ height: "4rem", width: "6rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Native App Development:</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    As a native app development company specializes in the
                    creation of applications meticulously tailored for a
                    specific operating system, be it iOS or Android. They
                    harness platform-specific programming languages and tools to
                    craft these applications.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="card" id="card-design">
                <img
                  src={mobliewebapp}
                  className="m-auto mt-3"
                  style={{ height: "4rem", width: "5rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Web App Development:</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    Our Web app development services are adept at crafting
                    programs accessible via mobile devices through web browsers.
                    Leveraging web technologies such as HTML, CSS, and
                    JavaScript, and collaborating with a leading social media
                    marketing agency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card " id="card-design">
                <img
                  src={mobliehybrid}
                  className="m-auto mt-3"
                  style={{ height: "4rem", width: "5rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Hybrid App Development:</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    Hybrid apps amalgamate native and web app elements. While
                    they are constructed using web technologies, a native
                    container provides access to device functionalities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="margintop">
            <div className="col-md-4 mt-2">
              <div className="card" id="card-design">
                <img
                  src={moblieprogress}
                  className="m-auto mt-3"
                  style={{ height: "4rem", width: "10rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Progressive Web Apps (PWAs):</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    As a preeminent mobile app development company we seamlessly
                    amalgamate mobile and web app technologies to users in
                    Progressive Web Apps (PWAs). These groundbreaking
                    applications can be effortlessly added to the home screen.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="card" id="card-design">
                <img
                  src={moblierapid}
                  className="m-auto mt-3"
                  style={{ height: "4rem", width: "5rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Rapid Mobile App Development (RMAD):</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    Our approach revolves around transparency.We consistently
                    report on Rapid Mobile App Development (RMAD) platforms that
                    empower users to rapidly construct mobile apps using
                    pre-designed templates and user-friendly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" id="card-design">
                <img
                  src={mobliecross}
                  className="m-auto mt-3"
                  style={{ height: "4.2rem", width: "5rem" }}
                />
                <div className="card body" id="cardhd">
                  <h6 id="h6height">Cross platform app development:</h6>
                  <div className="underline"></div>
                  <p className="para-text">
                    The strategic paradigm known as cross-platform app
                    development allows for the deployment of a single codebase
                    across various platforms and devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <h3 className="mt-5  text-center">Technology we use</h3>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 20,
              marginBottom: 30,
            }}
          ></div>
          <div className="container-2">
            <div className="card-group my-5" id="mb1">
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://developer.android.com/docs"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={android011}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://www.swift.org/documentation/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={ab}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://legacy.reactjs.org/docs/getting-started.html"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={react1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://docs.flutter.dev/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={flutter1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://play.google.com/store/apps/details?id=com.google.android.apps.docs.editors.docs&hl=en_IN&gl=US"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={google}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://apps.apple.com/us/app/google-docs-sync-edit-share/id842842640"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={ab2}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://angular.io/docs"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={angular1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardsradius"
              >
                <Link
                  to="https://nodejs.org/en/docs"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={node1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center mt-5">
            <h2 style={{ paddingTop: "3rem", color: "#155ea3" }}>
              Unleash Your App Idea Today!
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
              Do you have an innovative app idea that has the potential to
              change the digital landscape? It's time to transform your vision
              from a mere concept into a reality. Our knowledgeable team of
              mobile app developers is prepared to assist you at each stage of
              the development process.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Mobiledevolopment;

const Wrapper = styled.div`
  #cont {
    height: 35rem;

    @media screen and (max-width: 768px) {
      height: 56rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 60rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  .para-text {
    text-align: center;
  }
  #card-design {
    border: none;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: em;
      border: solid 1px;
    }
  }
  #card-design:hover {
    box-shadow: 20px 20px 20px 1px whitesmoke;
  }

  #smd {
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }
  #mob {
    @media screen and (max-width: 768px) {
      margin-left: -1.5rem;
    }
  }

  #cardsradius {
    border-radius: 2rem;
    @media screen and (max-width: 768px) {
      height: 14rem;
      width: 14rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 3rem;
    }
  }
  #imgs {
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 2rem;
    }
  }
  #mb1 {
    @media screen and (max-width: 768px) {
      margin-left: 3.2rem;
    }
  }
  #mobhero {
    margin-top: 8rem;
    margin-left: 4rem;
    @media screen and (max-width: 768px) {
      margin-top: 4rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      margin-top: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-top: 3rem;
      margin-left: 6.6rem;
    }
  }
  .mm {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  .mm1 {
    margin-top: 3rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  #margintop {
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 0rem;
    }
  }
  #h6height {
    text-align: center;

    margin: 25px;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  #cardres {
    height: 21rem;
    border: none;
    padding: 15px;
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }
  #mbapp {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: -0.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 1rem;
    }
  }
  #cardhd {
    height: 250px;
    border: none;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 25rem;
    }
  }
  .ws {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
