import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog9 from "../images/blog9.jpg";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

function Blog9() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Best SEO Company in Madhya Pradesh

        </title>
        <meta
          name="description"
          content=" Looking for the best SEO company in Madhya Pradesh? DOAguru Infosystems delivers top-notch SEO services to boost your online presence. Discover why we're the leading SEO company in Madhya Pradesh."
        />
        <link rel="canonical" href="https://doaguru.com/Best-SEO-company-in-Madhya-pradesh" />
        {/* <Link rel="canonical" to="https://doaguru.com/Best-Software-Development-Company-in-Jabalpur" /> */}

      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Boost Your Online Presence: Best SEO Company in Madhya Pradesh
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={blog9} alt="" className="frontimg rounded" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today's era establishing an online presence is essential,
                    for any business looking to succeed in the competitive
                    market environment. With websites competing for attention,
                    how can yours make a mark? The key lies in SEO (Search
                    Engine Optimization) which plays a role in enhancing
                    visibility. When it comes to boosting your visibility and
                    improving search engine rankings DOAGuru Infosystems emerges
                    as the choice being recognized as the premier SEO Company in
                    Jabalpur.
                  </p>
                </div>
              </div>

              <div className="col-lg-12  mt-3">
                <div data-aos="fade-left">
                  <p>
                    DOAGuru Infosystems isn't another{" "}
                    <Link
                      to="https://doaguru.com/Best-Software-Company-In-Jabalpur"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      IT Solution Company in Jabalpur
                    </Link>{" "}
                    . It stands out as a hub of creativity, expertise, and
                    commitment to ensuring your business shines amidst the
                    landscape. Specializing in SEO services they possess
                    insights into search engine algorithms. Implement
                    state-of-the-art strategies to elevate your website
                    position, in search results.
                  </p>
                  <p>
                    What makes{" "}
                    <Link
                      to="/"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      DOAGuru Infosystems
                    </Link>{" "}
                    stand out as the{" "}
                    <Link
                      to="https://doaguru.com/Best-Software-Company-In-Jabalpur"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      Best SEO company in MP
                    </Link>
                    ? It's more than using strategies; it's about creating
                    personalized SEO solutions designed to address the specific
                    requirements of your business. Whether you're a startup or a
                    standing corporation their group of experienced SEO
                    professionals dedicates themselves to developing plans that
                    increase natural traffic improve visibility and ultimately
                    enhance your profits.
                  </p>
                  <h6>
                    {" "}
                    Contact us:-{" "}
                    <Link
                      to="/contact_us"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      {" "}
                      DOAGuru Infosystems
                    </Link>
                  </h6>
                </div>
                <hr />
              </div>
              {isShowMore && (
                <p>
                  <h3>
                    Here are the reasons why DOAGuru Infosystems is your
                    companion, for SEO success;
                  </h3>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Tailored Approaches:</h5>
                      <p>
                        SEO strategies are not one size fits all. DOAGuru
                        Infosystems takes the time to understand your business
                        target audience and industry dynamics to create
                        customized SEO plans that produce results.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Transparency:</h5>
                      <p>
                        There are no motives or secret methods here. DOAGuru
                        Infosystems prioritizes transparency throughout the
                        process. You'll be informed with updates and reports, on
                        how your website's performing.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Proven Track Record::</h5>
                      <p>
                        DOAGuru Infosystems has a history of achieving outcomes
                        for clients in industries establishing itself as the top
                        choice for{" "}
                        <Link
                          to="/seo"
                          target="_blank"
                          className="text-decoration-none"
                        >
                          SEO services in Jabalpur
                        </Link>
                        . Their success stories demonstrate their ability to
                        deliver results-oriented SEO solutions.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Continuous Improvement:</h5>
                      <p>
                        SEO isn't a one-and-done task; it requires attention.
                        DOAGuru Infosystems monitors, analyzes, and fine-tunes
                        your SEO strategies to adapt to algorithm changes and
                        ensure growth in search engine rankings.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Comprehensive Offerings:</h5>
                      <p>
                        From researching keywords and optimizing, on-page
                        content to creating engaging material and building
                        quality links DOAGuru Infosystems provides a range of{" "}
                        <Link
                          to="/seo"
                          target="_blank"
                          className="text-decoration-none"
                        >
                          SEO services
                        </Link>{" "}
                        to enhance all aspects of your presence.
                      </p>
                      <p>
                        If you're looking to boost your presence and stand out
                        in the world consider partnering, with DOAGuru
                        Infosystems,{" "}
                        <Link
                          to="/seo"
                          target="_blank"
                          className="text-decoration-none"
                        >
                          {" "}
                          the best SEO Company in Jabalpur
                        </Link>
                        . Their team's knowledge, passion, and dedication to
                        your success make reaching top search engine rankings
                        and increasing traffic more than a dream – it's a
                        tangible goal waiting to be achieved. Reach out to
                        DOAGuru Infosystems today. Start your path, toward SEO
                        success!
                      </p>
                    </div>
                    <hr />
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read on"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog9;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
`;
