import React from "react";
import doaguru from "../../components/images/doagurulogo-removebg.webp";
import bg from "../../components/images/newbg.webp";
import "../Homepages/Footer.css";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  AiFillEnvironment,
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillMail,
  AiFillPhone,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { FaPinterestSquare, FaTwitterSquare } from "react-icons/fa";

const Footer = () => {
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Container>
        <footer
          style={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="content ">
            <div className="left box text-black">
              <div className="lower">
                <div className="topic mt-4">Contact us</div>
                <div className="ct text-white">
                  <div className="loc d-flex">
                    <div>
                      <AiFillEnvironment size="35px" />{" "}
                    </div>
                    <div>
                      <span className="m-1">
                        1815 Wright Town,
                        <br /> Jabalpur, Madhya pradesh INDIA <br />
                        482002
                      </span>
                    </div>
                  </div>

                  <br />
                  <AiFillPhone size="35px" />
                  <span className="text-white">
                    <Link
                      to="tel:+91-8827311424"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      +91-8827311424
                    </Link>
                  </span>
                  <br />
                  <AiFillPhone size="35px" />
                  <span className="text-white">
                    <Link
                      to="tel:+91-7440992424"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      +91-7440992424
                    </Link>
                  </span>
                  <br />
                  <br />
                  <AiFillMail size="35px" />
                  <span className="m-2 text-white">
                    {" "}
                    <Link
                      to="mailto:info@doaguru.com"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      info@doaguru.com
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="middle box" id="middle2">
              <div className="topic mt-4">Our's Services</div>
              <div className="hd">
                {" "}
                <Link
                  to="/Best-Software-Company-In-Jabalpur"
                  onClick={handleFooterLink}
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Website Designing{" "}
                </Link>
              </div>

              <div className="hd">
                {" "}
                <Link
                  to="/mobile-development"
                  onClick={handleFooterLink}
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Mobile Application{" "}
                </Link>
              </div>
              <div>
                <Link
                  to="/Best-Digital-Marketing-Company-in-Jabalpur"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Digital Marketing
                </Link>
              </div>
              <div>
                <Link
                  to="/Best-SEO-Company-in-Jabalpur"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  SEO Services
                </Link>
              </div>
              <div>
                {" "}
                <Link
                  to="/graphic-designing"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Graphic Designing{" "}
                </Link>
              </div>
              <div>
                <Link
                  to="/Best-social-media-marketing-company-in-Jabalpur-India"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  SMM services
                </Link>
              </div>
            </div>
            <div className="middle box">
              <div className="topic mt-4">Link List</div>
              <div>
                {" "}
                <Link
                  to="/about_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  About
                </Link>
              </div>
              <div>
                {" "}
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Contact Us
                </Link>
              </div>
              <div>
                {" "}
                <Link
                  to="/career"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Career{" "}
                </Link>
              </div>

              <div>
                {" "}
                <Link
                  to="/portfolio"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Portfolio
                </Link>
              </div>
              <div>
                {" "}
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Blogs
                </Link>
              </div>
              <div>
                {" "}
                <Link
                  to="/sitemap"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Sitemap
                </Link>
              </div>
            </div>

            <div className="right box mt-4">
              <div className="image">
                <Link to="/" onClick={handleFooterLink}>
                  <img src={doaguru} alt="doaguru logo" id="doagurulogo" />
                </Link>
              </div>
              <div>
                <div className="topic text-center  mt-4" id="follow">
                  Follow us on
                </div>
                <div className="d-flex justify-content-center" id="logo">
                  <Link
                    to="https://www.facebook.com/Digitalmarketingandwebsitedesigncompany/"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillFacebook
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "#3b5998")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/doaguru-infosystems/"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillLinkedin
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "#0A66C2")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/doaguru_infosystem?igsh=MTdzdDdjdDAxNGVuOQ=="
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <AiFillInstagram
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "#da1e1e")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                    />
                  </Link>
                  <Link
                    to="https://x.com/DoaguruS"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <FaTwitterSquare
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "#00acee")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                    />
                  </Link>
                  <Link
                    to="https://pin.it/6yE5MBu"
                    onClick={handleFooterLink}
                    target="_blank"
                    rel="norefferel"
                    className="text-white m-2"
                  >
                    <FaPinterestSquare
                      size="30px"
                      onMouseOver={({ target }) =>
                        (target.style.color = "	#E60023")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="pp mb-4 text-center">
              Copyright © 2023{" "}
              <Link target="_blank" to="/Admin-Login">
                DOAGuru InfoSystems
              </Link>{" "}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <ul className="d-flex justify-content-center align-items-center">
                <li onClick={handleFooterLink}>
                  <Link to="/terms-&-condition">Terms & Condition</Link>
                </li>
                <span className="span-li">|</span>
                <li onClick={handleFooterLink}>
                  <Link to="/privacy-policy"> Privacy Policy</Link>
                </li>
                <span className="span-li">|</span>
                <li onClick={handleFooterLink}>
                  <Link to="/refund-&-cancel"> Refund & Cancel</Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </Container>
    </>
  );
};

export default Footer;
const Container = styled.div`
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: white;
  }
  .span-li {
    padding: 5px;
  }
`;
