import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog3 from "../images/blogimg3.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";

function Blog2() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Email Marketing: Because Your Inbox Was Feeling Lonely
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={blog3} alt="" className="frontimg rounded" />
                </div>
              </div>
              <div className="col-lg-12  mt-3">
                <div data-aos="fade-left">
                  <h3>Introduction to Email Marketing</h3>
                  <p>
                    In a world saturated with Digital marketing services, email
                    marketing remains a cornerstone of communication strategies
                    for businesses worldwide. Its enduring effectiveness in
                    engaging audiences, fostering relationships, and driving
                    conversions has cemented its relevance, making it a
                    quintessential tool in the marketer's arsenal.
                  </p>
                </div>
                <hr />
              </div>
              {isShowMore && (
                <p>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Building an Email List</h3>
                      <p>
                        Creating a robust subscriber base isn't just about
                        numbers; it's about cultivating an audience genuinely
                        interested in your brand. Employing organic methods,
                        such as website sign-ups, social media integration, and
                        valuable lead magnets, fosters a quality over quantity
                        approach.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Crafting Compelling Email Content</h3>
                      <p>
                        {" "}
                        The success of an email campaign hinges on the content
                        within. Crafting attention-grabbing subject lines,
                        incorporating visually appealing designs, and delivering
                        value-packed content with clear CTAs are paramount for
                        engaging readers.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Segmentation and Personalization</h3>
                      <p>
                        Tailoring messages to specific audience segments
                        significantly enhances engagement. Effective
                        segmentation and personalization strategies ensure that
                        subscribers receive content relevant to their interests,
                        boosting open rates and conversions.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Automation in Email Marketing</h3>
                      <p>
                        Automation streamlines processes, allowing for timely
                        and personalized communication. Implementing automated
                        workflows for welcome sequences, abandoned carts, and
                        re-engagement campaigns maximizes efficiency and
                        nurtures leads.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Measuring Success: Analytics and Metrics</h3>
                      <p>
                        Understanding the metrics and KPIs pertinent to email
                        marketing is crucial. Analyzing open rates,
                        click-through rates, conversion rates, and other data
                        provides insights into campaign performance and audience
                        behavior.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Best Practices and Trends</h3>
                      <p>
                        Staying ahead in the email marketing landscape means
                        keeping up with evolving best practices and emerging
                        trends. Leveraging interactive content,
                        hyper-personalization, and accessibility features are
                        among the latest trends driving engagement.
                      </p>
                    </div>
                    <hr />
                  </div>

                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Conclusion</h3>
                      <p>
                        Email marketing, a versatile and enduring tool,
                        continues to thrive in a digital realm cluttered with
                        various communication channels. Its ability to deliver
                        targeted, personalized content to audiences remains
                        unparalleled, making it an indispensable asset for
                        businesses aiming to forge meaningful connections with
                        their customers.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>FAQ</h3>
                      <ul>
                        <li className="h6">
                          How often should I send marketing emails ?
                        </li>
                      </ul>
                      <p>
                        The frequency depends on your audience and content
                        relevance. Aim for a balance, avoiding excessive emails
                        that might lead to unsubscribes.
                      </p>
                      <ul>
                        <li className="h6">
                          What is the significance of A/B testing in email
                          marketing ?
                        </li>
                      </ul>
                      <p>
                        A/B testing allows you to experiment with different
                        elements (subject lines, content, visuals) to optimize
                        campaign performance based on audience preferences.
                      </p>
                      <ul>
                        <li className="h6">
                          Can email marketing work for small businesses ?
                        </li>
                      </ul>
                      <p>
                        Absolutely! Email marketing offers cost-effective ways
                        to engage and convert leads, making it an ideal strategy
                        for small businesses to reach their audience.
                      </p>
                      <ul>
                        <li className="h6">
                          How important is mobile optimization in email
                          marketing ?
                        </li>
                      </ul>
                      <p>
                        With a majority of users accessing emails on mobile
                        devices, optimizing emails for mobile responsiveness is
                        crucial for better engagement and readability.
                      </p>
                      <ul>
                        <li className="h6">
                          What role does storytelling play in email content ?
                        </li>
                      </ul>
                      <p>
                        Storytelling humanizes brands, resonates with audiences,
                        and fosters stronger emotional connections, making it a
                        powerful tool in email marketing campaigns
                      </p>
                      <p>
                        In essence, the{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          {" "}
                          Best Digital Marketing Company{" "}
                        </Link>
                        acknowledges the enduring efficacy of email marketing,
                        utilizing it as a cornerstone for engagement and
                        conversion strategies..
                      </p>
                    </div>
                    <hr />
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read on"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog2;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    height: 25rem;
    width: 30rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }
`;
