import React from "react";
import Finalnav from "../Homepages/Finalnav";
import styled from "styled-components";

import ipad from "../../components/img/ipad.png";
import mobile from "../../components/img/mobile.png";
import { Link } from "react-router-dom";
import Footer from "../Homepages/Footer";

const Animation = () => {
  return (
    <>
      <Finalnav />
      <Motions>
        <div className="container-fluid">
          <div className="row d-flex gap-5">
            <div className="col-12  d-flex justify-content-center">
              {/* doaGurcard start */}
              <div className="cardDoa mt-5">
                <div className="row p-5 h-100 d-flex align-items-center gap-3 gap-md-0">
                  <div className="col-md-8">
                    <div className="row d-flex gap-4">
                      <div className="col-12">
                        <h2>Animation Workshop</h2>
                      </div>
                      <div className="col-12">
                        <p>
                          Your brand story deserves to be animated! At DOAGuru
                          InfoSystems, we collaborate closely with you to
                          understand your vision and translate it into a
                          captivating motion design experience. We believe the
                          best stories are told together, and yours is no
                          exception!
                        </p>
                      </div>
                      <div className="col-12">
                        <Link
                          to="/contact_us"
                          className="doaBtn border-0 text-white p-2 rounded-3"
                        >
                          Connect Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex justify-content-center">
                    <img
                      className="img-fluid rounded-1"
                      style={{ width: "210px" }}
                      src="https://thumbs.dreamstime.com/b/online-training-workshop-courses-visualization-online-training-workshops-courses-visualization-small-people-look-huge-143197686.jpg"
                    />
                  </div>
                </div>
              </div>
              {/* doaGurcard end */}
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="bg-dark rounded-2"
                style={{
                  height: "7px",
                  width: "90px",
                }}
              ></div>
            </div>

            <div className="col-12 d-flex justify-content-center align-items-center">
              <h1>iPad and Laptop</h1>
            </div>

            {/* main work  */}
            <div className="col-12 d-flex justify-content-center flex-wrap">
              <div className="MotionCom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">
                {/* <div className="ipad">
                  <img className="img-fluid" src={ipad} />
                  <div className="videF">
                    <video
                      className="w-100 h-100"
                      muted
                      autoplay
                      loop
                      controls
                      src={"https://www.youtube.com/live/oS9YrC_aQEg?si=ZxhOG_Wuo5jNJNKe"}
                    ></video>
                  </div>
                </div> */}

                <div className="ipad">
                  <img className="img-fluid" src={ipad} />
                  <div className="videF">
                    <iframe
                      className="w-100 h-100"
                      src="https://www.youtube-nocookie.com/embed/KcqD7Vnfe60?si=Uu2Zgl58G-0Yksi"
                    ></iframe>
                  </div>
                </div>

                <div className="ipad">
                  <img className="img-fluid" src={ipad} />
                  <div className="videF">
                    <iframe
                      className="w-100 h-100"
                      src="https://www.youtube.com/embed/oRXlfmRghVQ?si=k1lIoXj6S49ddEaf"
                    ></iframe>
                  </div>
                </div>

                <div className="ipad">
                  <img className="img-fluid" src={ipad} />
                  <div className="videF">
                    <iframe
                      className="w-100 h-100"
                      src="https://www.youtube.com/embed/QbLkfRZFNzg?si=OVlu-L1tk_MgTHd9"
                    ></iframe>
                  </div>
                </div>

                <div className="ipad">
                  <img className="img-fluid" src={ipad} />
                  <div className="videF">
                    <iframe
                      className="w-100 h-100"
                      src="https://www.youtube.com/embed/K_W4w8YORFI?si=oZDV0MYrGxLyr4dY"
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-center">
                <h1>Mobile</h1>
              </div>

              <div className="col-12 d-flex justify-content-center flex-wrap">
                <div className="MotionCom d-flex flex-wrap gap-5 gap-lg-5  justify-content-evenly">
                  {/* <div className="mobile">
                    <img className="img-fluid" src={mobile} />
                    <div className="mobileF">
                      <video
                        className="w-100 h-100"
                        autoplay
                        loop
                        muted
                        controls
                        src={""}
                      ></video>
                    </div>
                  </div> */}

                  <div className="mobile">
                    <img className="img-fluid" src={mobile} />
                    <div className="mobileF">
                      <iframe
                        className="w-100 h-100"
                        src="https://www.youtube.com/embed/QbLkfRZFNzg?si=OVlu-L1tk_MgTHd9"
                      ></iframe>
                    </div>
                  </div>

                  <div className="mobile">
                    <img className="img-fluid" src={mobile} />
                    <div className="mobileF">
                      <iframe
                        className="w-100 h-100"
                        src="https://www.youtube.com/embed/oRXlfmRghVQ?si=k1lIoXj6S49ddEaf"
                      ></iframe>
                    </div>
                  </div>

                  <div className="mobile">
                    <img className="img-fluid" src={mobile} />
                    <div className="mobileF">
                      <iframe
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        className="w-100 h-100"
                        src="https://www.youtube.com/embed/K_W4w8YORFI?si=oZDV0MYrGxLyr4dY"
                      ></iframe>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* main work  */}
          </div>
        </div>
      </Motions>
      <Footer />
    </>
  );
};

export default Animation;

const Motions = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 29px;
  background-color: #e2eafc;

  //  btn
  button,
  a {
    background-color: rgb(0, 43, 98);
    cursor: pointer;
    position: relative;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  button:hover,
  a:hover {
    transform: scale(0.9);
    background-color: green;
    color: white;
  }

  //cardDoa
  .cardDoa {
    width: 900px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.253);
    transition: all 0.4s;
    background-color: black;
    color: white;
    cursor: pointer;
  }
  .cardDoa:hover {
    transform: scale(0.9);
    background-color: white;
    color: black;
  }

  // iPad

  .MotionCom {
    width: 1200px;
  }

  .ipad {
    position: relative;
    transition: all 0.6s;
    cursor: pointer;
    width: clamp(300px, 70%, 500px);
    min-width: 290px;
  }
  .ipad:hover {
    transform: scale(0.9);
  }
  .ipad .videF {
    background-color: black;
    width: 76%;
    height: 80%;
    position: absolute;
    top: 5%;
    left: 12%;
    cursor: pointer;
  }

  // mobile
  .mobile {
    width: 290px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 2;
    transition: all 0.5s;
  }
  .mobile:hover {
    transform: scale(0.9);
  }

  .mobileF {
    background-color: black;
    width: 86%;
    height: 94%;
    position: absolute;
    top: 3%;
    left: 7%;
    z-index: 5;
    overflow: hidden;
    border-radius: 8%;
  }
`;
