import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog6 from "../images/blog6.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";

function Blog6() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              Cybersecurity Essentials: Protecting Your Business in a Digital
              Age
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={blog6} alt="" className="frontimg rounded" />
                </div>
              </div>
              <div className="col-lg-12  mt-3">
                <div data-aos="fade-left">
                  <h3>Introduction to Email Marketing</h3>
                  <p>
                    In today's era the need, for cybersecurity measures is more
                    crucial than ever before. As businesses increasingly rely on
                    platforms the risk of cyber threats becomes a concern.
                    DOAGuru Infosystems, renowned as the{" "}
                    <Link
                      to="/"
                      target="_blank"
                      className=" text-decoration-none"
                    >
                      {" "}
                      best digital marketing company in Jabalpur{" "}
                    </Link>{" "}
                    is understands the importance of cybersecurity and advocates
                    for essential practices to protect businesses in this
                    digital age.
                  </p>
                </div>
                <hr />
              </div>
              {isShowMore && (
                <p>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h2>The Growing Significance of Cybersecurity</h2>
                      <p>
                        With the rise of digitization businesses face cyber
                        threats such as data breaches, ransomware attacks, and
                        phishing scams. DOAGuru Infosystems acknowledges that
                        taking an approach to cybersecurity is not only
                        necessary but also vital, for business success.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>1. Ensuring Employee Training and Awareness:</h3>
                      <p>
                        {" "}
                        Cybersecurity breaches often occur due, to errors. At
                        DOAGuru Infosystems we prioritize training programs for
                        employees to improve their understanding of
                        cybersecurity and encourage digital practices.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>2. Strengthening Password Policies: </h3>
                      <p>
                        The first line of defense is having unique passwords. At
                        DOAGuru Infosystems we strongly advocate for
                        implementing password policies, including updates and
                        multi-factor authentication. These measures enhance
                        access controls and safeguard against security threats.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>
                        3. Regular Software Updates and Patch Management:{" "}
                      </h3>
                      <p>
                        Keeping your software up-to-date and managing patches is
                        crucial to protect against vulnerabilities. At DOAGuru
                        Infosystems we recommend updates. Patch management to
                        eliminate any potential security loopholes making sure
                        your systems are well protected from evolving threats.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>4. Secure Network Infrastructure: </h3>
                      <p>
                        To have a cybersecurity strategy, network
                        configurations, and firewall protection are important.
                        At DOAGuru Infosystems we specialize in fortifying
                        network infrastructures creating defenses against
                        access.
                      </p>
                    </div>
                    <hr />
                  </div>

                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>DOAGuru Infosystems Strategy, for Cybersecurity</h3>
                      <p>
                        Being the{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          {" "}
                          best digital marketing company in Jabalpur{" "}
                        </Link>{" "}
                        DOAGuru Infosystems seamlessly incorporates
                        cybersecurity into its range of services. They
                        prioritize the security and protection of client data.
                        Ensure the strength of platforms.
                      </p>
                    </div>
                    <hr />
                  </div>

                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Protecting Your Digital Future</h3>
                      <p>
                        In today's changing world cybersecurity is a constantly
                        evolving field that demands ongoing adjustments. At
                        DOAGuru Infosystems we ensure that businesses not only
                        meet cybersecurity standards but also possess the
                        capabilities to tackle future challenges head-on.
                      </p>
                      <p>
                        Suppose you're looking for guidance, on cybersecurity
                        practices. Strengthening your business against online
                        threats turn to DOAGuru Infosystems – your trusted ally,
                        in navigating the digital realm.
                      </p>
                    </div>
                    <hr />
                  </div>

                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>FAQ</h3>
                      <ul>
                        <li className="h6">
                          Q1. How does employee training contribute, to
                          enhancing cybersecurity for my business?
                        </li>
                      </ul>
                      <p>
                        Employee training plays a role in bolstering
                        cybersecurity. At DOAGuru Infosystems we place
                        importance on educating our staff about identifying
                        phishing attempts practicing password management and
                        fostering a cyber-aware workplace.
                      </p>
                      <ul>
                        <li className="h6">
                          Q2. What is the significance of software updates in
                          maintaining cybersecurity? How does DOAGuru
                          Infosystems ensure it for its clients?
                        </li>
                      </ul>
                      <p>
                        Regular software updates and effective patch management
                        are crucial to address security vulnerabilities. To
                        ensure the security of our clients DOAGuru Infosystems
                        implements measures such as automated updates and
                        continuous monitoring to fortify their systems, against
                        emerging threats.
                      </p>
                      <ul>
                        <li className="h6">
                          Q3. How does DOAGuru Infosystems tackle the
                          cybersecurity issues that businesses, in Jabalpur and
                          the surrounding regions face?
                        </li>
                      </ul>
                      <p>
                        DOAGuru Infosystems acknowledges the cybersecurity
                        challenges faced by businesses in Jabalpur and
                        understands the intricacies. Their approach involves
                        conducting an evaluation of cyber threats and providing
                        customized solutions to address the distinct
                        cybersecurity landscape of the area.
                      </p>
                      <ul>
                        <li className="h6">
                          Q4. Apart from technology how does DOAGuru Infosystems
                          take into account the aspect of cybersecurity?
                        </li>
                      </ul>
                      <p>
                        DOAGuru Infosystems gives importance to the element by
                        organizing regular training programs raising awareness
                        through campaigns and fostering a culture of
                        cybersecurity consciousness, among employees. This
                        approach makes them an integral part of defending
                        against cyber threats.
                      </p>
                    </div>
                    <hr />
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read on"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog6;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    height: 25rem;
    width: 30rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }
`;
