import React from "react";
import newhome from "../../components/images/newhome.jpeg";
// import "../Homepages/Homee.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import backimg from "../../components/images/2.png";

const ImageTextPage = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <>
      <Wrapper>
        <div className="container pb-5">
          <h2 className="text-center text-capitalize text-light pt-5">
            Our comprehensive IT services
          </h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "10rem",
              backgroundColor: "#86ff68",
              marginTop: 10,
              marginBottom: 10,
            }}
          ></div>
          <div data-aos="fade-up" data-aos-duration="3000">
            <p className="text-center text-light mb-5">
              Your reliable strategic partner, Doaguru, lives up to the
              statement Brilliance Is Our Business. Being among the best IT
              firms in Jabalpur and all of India, we're dedicated to providing
              IT services that surpass clients' expectations.
            </p>
            <p className="text-light">
              Through Connected Creativity, we generate content that genuinely
              matters by skillfully fusing IT solutions with our knowledge of
              digital marketing.
            </p>
            <p className="text-light">
              The core of our strategy is Banding It Better. We think that even
              the tiniest concepts have the power to expand brands and spark
              innovation. Not only do we think big at Doaguru, we think giant.
              We are the engine of your success, bringing revenue straight to
              your doorstep.
            </p>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ImageTextPage;
const Wrapper = styled.div`

  background: #002c63;
  a {
    text-decoration: none;
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  #home {
    margin-top: 60px;
  }
  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;
