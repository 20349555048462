import React, { useEffect, useState } from "react";
import styled from "styled-components";
import doaguru from "../../components/images/doagurulogo-removebg.webp";
import { Link } from "react-router-dom";
import axios from "axios";

const Finalnav = ({ toggle }) => {
  const [toggleBtn, setToggelBtn] = useState(true);

  const protecteApi = async (z) => {
    try {
      let data = await axios.post(
        `https://doaguru.com/api/auth/protected/${z}`
      );
      const { user } = data.data;
      if (user) {
        setToggelBtn(false);
      } else {
        setToggelBtn(true);
      }
    } catch (error) {
      console.log("err protected");
    }
  };

  let FechAuth = () => {
    let token = localStorage.getItem("token");
    if (token?.length > 0) {
      protecteApi(token);
    } else {
      protecteApi("null");
    }
  };

  useEffect(() => {
    FechAuth();
  }, [toggle]);

  const gradientBackground = "linear-gradient(to right, #00e5e5, #86ff68)";
  const NavtBackground = "linear-gradient(to right, #86FF68, #f8f9fa)";
  return (
    <Wrapper>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid py-2" id="head">
          <Link className="navbar-brand" href="/" id="logores">
            <img src={doaguru} alt="Logo" width="160" height="80" />{" "}
          </Link>
          <button
            className="navbar-toggler text-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{  backgroundColor: "cornflowerblue"}}
            ></span>
          </button>

          <div className="collapse navbar-collapse align-middle" id="navbarNav">
            <ul
              className="navbar-nav  nav_ul align-items-center mx-auto"
              id="navleft"
            >
              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/about_us" style={{ textDecoration: "none" }}>
                    {" "}
                    About Us
                  </Link>
                </Link>
              </li>

              <div className="btn-group">
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0.5rem", color: "white" }}
                >
                  Services
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ padding: "1rem", background: "#002c63" }}
                >
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/Best-SEO-Company-in-Jabalpur"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        SEO Service
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link to="/Best-social-media-marketing-company-in-Jabalpur-India" style={{ textDecoration: "none" }}>
                        SMM Service
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link to="/ppc" style={{ textDecoration: "none" }}>
                        {" "}
                        PPC Google Adwords
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/Best-Digital-Marketing-Company-Jabalpur"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Digital Marketing
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/Top-Software-Company-in-Jabalpur"
                        style={{ textDecoration: "none" }}
                      >
                        Software and Website Developement
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/mobile-development"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Mobile App Development{" "}
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/graphic-designing"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Graphic Designing{" "}
                      </Link>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <Link to="/pr-service" style={{ textDecoration: "none" }}>
                        {" "}
                        Pr Service{" "}
                      </Link>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="btn-group">
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0.5rem", color: "white" }}
                >
                  Our Products
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ padding: "1rem", background: "#002c63" }}
                >
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/dental-guru"
                        style={{ textDecoration: "none" }}
                      >
                        DentalGuru
                      </Link>
                    </Link>
                  </li> <hr style={{ color:"white" }} />
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/mini-dental-guru"
                        style={{ textDecoration: "none" }}
                      >
                        Lite DentalGuru
                      </Link>
                    </Link>
                  </li><hr style={{ color:"white" }} />
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/Quotation Generator Management System"
                        style={{ textDecoration: "none" }}
                      >
                        Quotation Generator
                      </Link>
                    </Link>
                  </li><hr style={{ color:"white" }} />
                  <li>
                    <Link className="dropdown-item">
                      <Link
                        to="/Doaguru CRM"
                        style={{ textDecoration: "none" }}
                      >
                        DG CRM
                      </Link>
                    </Link>
                  </li><hr style={{ color:"white" }} />
                </ul>
              </div>

              {/* work  */}

              {/* <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  <Link to="/portfolio" style={{ textDecoration: "none" }}>
                    Portfolio
                  </Link>
                </Link>
              </li> */}

              {toggleBtn ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/portfolio">
                    <Link to="/portfolio" style={{ textDecoration: "none" }}>
                      Portfolio
                    </Link>
                  </Link>
                </li>
              ) : (
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "0.5rem", color: "white" }}
                  >
                    Portfolio
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ padding: "1rem", background: "#002c63" }}
                  >
                    <li className="nav-item">
                      <Link className="nav-link" to="/portfolio">
                        <Link
                          to="/portfolio"
                          style={{ textDecoration: "none" }}
                        >
                          Portfolio
                        </Link>
                      </Link>
                    </li>

                    {toggleBtn ? null : (
                      <>
                        <li className="nav-item">
                          <Link className="nav-link" to="/portfolio">
                            <Link
                              to="/webbased"
                              style={{ textDecoration: "none" }}
                            >
                              Web Based
                            </Link>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" to="/portfolio">
                            <Link
                              to="/animation"
                              style={{ textDecoration: "none" }}
                            >
                              Animation
                            </Link>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" to="/portfolio">
                            <Link
                              to="/software"
                              style={{ textDecoration: "none" }}
                            >
                              Softwares
                            </Link>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" to="/portfolio">
                            <Link
                              to="/smosmm"
                              style={{ textDecoration: "none" }}
                            >
                              SMO & SMM
                            </Link>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}

              {/* work  */}
              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/career" style={{ textDecoration: "none" }}>
                    Career
                  </Link>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/blogs" style={{ textDecoration: "none" }}>
                    Blogs
                  </Link>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/case-studies" style={{ textDecoration: "none" }}>
                    Case Studies
                  </Link>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link">
                  <Link to="/faq" style={{ textDecoration: "none" }}>
                    FAQ
                  </Link>
                </Link>
              </li>

              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    href="#"
                    id="comm"
                    style={{
                      padding: "0.5rem",
                      background: gradientBackground,
                    }}
                  >
                    <Link
                      to="/contact_us"
                      style={{
                        textDecoration: "none",
                        color: "black",
                        background: gradientBackground,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      Contact Us
                    </Link>
                  </Link>
                </li>
              </ul>
            </ul>
            <div className="btn-group"></div>
          </div>
        </div>
      </nav>
    </Wrapper>
  );
};

export default Finalnav;

const Wrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    /* background: #002c63; */
  }

  .nav_ul a {
    font-size: 1.05rem;
    color: white;
    font-weight: 400;
    letter-spacing: 0.5px;
    &:hover {
      background-color: #002c63;
    }
  }

  .nav_ul .nav-item:hover .nav-link::before {
    transform: scaleX(1);
  }

  .navbar-nav .nav-link {
    position: relative;
    transition: color 0.3s ease-in-out;
  }

  .navbar-nav .nav-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #6c63ff;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }
  #logores {
    margin-left: 1rem;
    @media screen and (max-width: 768px) {
      margin-left: 0.6rem;
    }
  }
  #navleft {
    margin-left: 7rem;
    gap: 0.3rem;
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
      margin-top: 0.5rem;
    }
  }
  #head {
    @media screen and (max-width: 768px) {
      padding: 0.5rem;
      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 1rem;
      }
    }
  }
  .bg-light {
    background: linear-gradient(
      266deg,
      #002c63,
      #002c63,
      #002c63,
      #002c63,
      #002c63,
      white
    );
  }
`;
