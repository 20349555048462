import React from "react";
import styled from "styled-components";
import ratings from "../../components/images/ratings.webp";
import { Link } from "react-router-dom";

function Testimonials() {
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <div
        className="container rounded"
        id="cont12"
      >
        <div className="row">
          <div className="col-lg-6 mt-4 d-flex align-items-center justify-content-center col-md-6">
            <div className="d-flex flex-column justify-content-start align-items-center">
              <h2 className="heading m-0 p-2" >
                Testimonials{" "}
                <div className="ul-head m-0 p-0">
                {/* <div
                  className="underline text-center"
                  style={{
                    height: 2,
                    width: "10rem",
                    backgroundColor: "#ffff",
                    marginTop: 2,
                    marginBottom: 10,
                  }}
                ></div> */}
                </div>
              </h2>

              <h6 className="text-white m-0 p-2" id="hh">What our client say</h6>
              <div className="mx-5">
                <Link
                  to="/portfolio"
                  onClick={handleFooterLink}
                  className="btn"
                  style={{ backgroundColor: " #FE7604", color: "#fff"}}
                  id="btn2"
                >
                   Read on
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6" id="res12">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div className="card">
                    <div className="card body" id="seohd">
                      <img src={ratings} className="rat" />
                      <div className="underline"></div>
                      <p className="para-text">
                        This is the one of the best IT company in M.P. which
                        takes care of the needs of the customers in a very good
                        way. It makes every possible effort to take the business
                        of its clients.
                      </p>
                      <h6 style={{ textAlign: "center", margin: "1rem" }}>
                        Hitesh Borkhade
                        
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="card body" id="seohd">
                    <img src={ratings} className="rat" />
                    <div className="underline"></div>
                    <p className="para-text">
                      One of the best company in jabalpur. Have a great
                      experience as a client. Quality and timely work done,
                      always listen to customers need. I'm satisfied with the
                      service of DOAGuru Infosystems.
                    </p>
                    <h6 style={{ textAlign: "center", margin: "1rem" }}>
                      ANIL RAJAK(Co-Founder of Bharat Roofers)
                    </h6>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="card body" id="seohd">
                    <img src={ratings} className="rat" />
                    <div className="underline"></div>
                    <p className="para-text">
                      I had used these services they are good services and best
                      in all over jabalpur and I look forward to continuing to
                      do business with them in the future. Keep up the fantastic
                      work!"
                    </p>
                    <h6 style={{ textAlign: "center", margin: "1rem" }}>
                      Priyanka Singh Lodhi thakur
                    </h6>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="card body" id="seohd">
                    <img src={ratings} className="rat" />
                    <div className="underline"></div>
                    <p className="para-text">
                      Very good place to take your business on hike with the
                      support of digital marketing and help od DOAGuru.the
                      experience was absolutely fantastic. The team went above
                      and beyond to meet my needs and expectations.
                    </p>
                    <h6 style={{ textAlign: "center", margin: "1rem" }}>
                      ATUL JAIN
                    </h6>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="card body" id="seohd">
                    <img src={ratings} className="rat" />
                    <div className="underline"></div>
                    <p className="para-text">
                      Best I.T company and the customer support was top-notch.
                      Whenever I had questions or needed assistance, their team
                      was quick to respond and incredibly helpful. .{" "}
                    </p>
                    <h6 style={{ textAlign: "center", margin: "1rem" }}>
                      Rewa nasha mukti kendra{" "}
                    </h6>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="card body" id="seohd">
                    <img src={ratings} className="rat" />
                    <div className="underline"></div>
                    <p className="para-text">
                      Excellent service. All are very co-operative and gave
                      their 100% till end user not satisfied. Thank You Team
                      DOAGuru. Will recommend to others at least once.
                    </p>
                    <h6 style={{ textAlign: "center", margin: "1rem" }}>
                      Sonu Kanojiya{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Testimonials;
const Wrapper = styled.div`
background: #002c63;
padding-top: 2rem;
padding-bottom: 2rem;
 #cont12{
  height: 23rem;
  background-color: #00284b;
  @media screen and (max-width: 768px) {
    height: 42rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1020px) {
    height: 23rem;
  }

}
#res12{
  margin-top: 4rem;
  margin-left: -3rem;
  @media screen and (max-width: 768px) {
    margin-left: 0rem;
  }
}
  .para-text {
    text-align: center;
    margin: 10px;
    margin-top: 0rem;
  }
  .rat {
    margin: auto;
    width: 10rem;
    margin-top: -1rem;
  }
  #seohd {
    height: 15rem;
    @media screen and (max-width: 768px) {
   height: 18rem;
  }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
    height:17rem;
  }
  


  }
  .heading {
    margin: 1rem;
    color: #00E5E5;
    margin-top: 5rem;
    @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }

  }
  #colno1 {
    margin-left: 6rem;
    @media screen and (max-width: 768px) {
    margin-left: 5rem;
  }
  }
  #hh{
    margin: 2rem;
    /* margin-top: 3rem; */
    @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }

  }
  h6{
    color: #4034db;;
  }
  .btn{
    background: linear-gradient(to right, #00e5e5, #86ff68);
  }
`;
