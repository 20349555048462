import React from "react";
import Finalnav from "./Finalnav";
import Cards from "./Cards";
import Homee from "./Homee";
import Header from "./Header";
import Service from "./Service";
import Carousel from "./Carousel";
import Connect from "./Connect";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Testimonials from "./Testimonials";
import Information from "./Information";
import HomeContent from "./HomeContent";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
// import backimg from "../../components/images/imgback.png";
// import backimg2 from "../../components/images/imgback2.png"; 

function Homepage() {
  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Digital Marketing Company in Jabalpur - DOAGuru Infosystems</title>
        <meta
          name="description"
          content="DOAGuru Infosystems, is the best digital marketing company in India. Our comprehensive range of services spans SEO, PPC, social media marketing, content marketing, email marketing, and more, tailored to meet the diverse needs of our clients all over India and beyond.
          "

        />
         <link rel="canonical" href="https://doaguru.com/"></link>
      </Helmet>
      <div style={{ overflow: "hidden" }}>
        <Finalnav />
        {/* <Breadcrumb/>  */}

        <Header />
        <Information /> 
        <HomeContent/>
        <ContactUs/>
        {/* <Connect /> */}
        <Homee />
        <Service />
        <Cards />
        <Carousel />
        <Testimonials />
        <Footer />
      </div>
    </Wrapper>
  );
}

export default Homepage;
const Wrapper = styled.div`
/* background-image: url();
  background-size: 100% 100%, 100% 100%;
  background-position: right top;
  background-repeat: no-repeat; */
`; 
