import React, { useState } from 'react';
import axios from 'axios';
import styled from "styled-components";
import backimg from "../../components/images/2.png"; 

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email_id: '',
    phone_no: '',
    message: ''
});
const [alertMessage, setAlertMessage] = useState('');

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('https://doaguru.com/api/auth/userdata', formData);
        console.log(response.data);
        setAlertMessage('Message sent successfully');
        setFormData({
          name: '',
          email_id: '',
          phone_no: '',
          message: ''
      });
    } catch (error) {
        console.error('Error:', error.response.data);
        setAlertMessage('Error: Message not sent');
    }
};

  return (
    <>
    <Wrapper>
        <div className="container-fluid pt-5 pb-5">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="contact-head">
                        <h2 className="text-center text-uppercase text-light">
                            Connect with us to Discuss More
                        </h2>
                        <div className="content">
                            <p className="text-center text-capitalize fs-3 text-light">
                                We’d love to help & work with you
                            </p>
                            <p className="text-center fs-6 text-light">
                                We’re always ready to listen to the needs of both new and
                                existing clients and work hard to provide you with a
                                solution that surpasses your expectations.
                            </p>
                        </div>
                    </div>
                    {alertMessage && <div className="alert text-center text-light">{alertMessage}</div>}
                    <div className="d-flex justify-content-center align-item-center conForm">
                       
                        <div className="innerForm">
                            <form onSubmit={handleSubmit}>
                                <div class="row mb-4">
                                    <div class="col">
                                        <div data-mdb-input-init class="form-outline">
                                            <input
                                                type="text"
                                                id="form3Example1"
                                                class="form-control"
                                                placeholder="Name*"
                                                name="name" value={formData.name} onChange={handleChange} required
                                            />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div data-mdb-input-init class="form-outline">
                                            <input
                                                type="text"
                                                id="form3Example2"
                                                class="form-control"
                                                placeholder="Phone Number*"
                                                name="phone_no" value={formData.phone_no} onChange={handleChange} required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div data-mdb-input-init class="form-outline mb-4">
                                    <input
                                        type="email"
                                        id="form3Example3"
                                        placeholder="Email Address*"
                                        class="form-control w-100"
                                        name="email_id" value={formData.email_id} onChange={handleChange} required
                                    />
                                </div>

                                <div data-mdb-input-init class="form-outline mb-4">
                                    <input
                                        type="text"
                                        id="form3Example4"
                                        placeholder="Message*"
                                        class="form-control w-100"
                                        name="message" value={formData.message} onChange={handleChange} required
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="submit">Send message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Wrapper>
</>
    );
  };

export default ContactUs;
const Wrapper = styled.div`
background-image: url(${backimg});
background-size: cover;
background-position: right;
background-repeat: no-repeat;
  /* background: #002c63; */
  .innerForm {
    width: 50%;
    box-shadow: 0px 10px 25px 3px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 60px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 280px) and (max-width: 1024px) {
      width: 100%;
    }
  }
  button {
    padding: 1rem;
    border-radius: 1rem;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    color: #002c63;
    font-weight: 600;
    border: none;
  }
  input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #34558a;
    border-radius: 0;
    font-family: inherit;
    font-size: 16px;
    height: 50px;
    padding: 0 20px;
    color: white;
    &::placeholder {
            color: gray;
        }
        &:focus {
        background: transparent;
        outline: none; 
        color: white;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #34558a;
    }
    
  }
  .conForm{
   
  }
`;
