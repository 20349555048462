import React from 'react'
import "../App.css"
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Slidebtn = ({pathData}) => {
  return (
    <Slide>
      <Link to="#webbased">
      <img className='img-fluid' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREzJKuOf7stzkBnhrE5dp-WpC0Y5KYK0NeN_itB3hzeA&s" alt="" />
      </Link>
    </Slide>
  )
}

export default Slidebtn


let Slide = styled.div`
  width: 60px;
  height: 60px;
  position: fixed;
  top: 90%;
  right: 2%;
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  transition: all .7s;
  
:hover{
  transform: scale(.9);
}

`