import React, { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import Finalnav from "../../page/Homepages/Finalnav";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import bgpr from "../images/bgpr.webp";
import { Link } from "react-router-dom";
import prhero from "../images/pr_hero.webp";
import Footer from "../../page/Homepages/Footer";

function Pr_Service() {
  useEffect(() => {
    Aos.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          PPC Power: Click, Convert, Succeed-Best Online marketing firm
        </title>
        <meta
          name="description"
          content="Drive Success with PPC Google AdWords: Measurable Results, Expert Techniques! Achieve PPC Victory 
          "
        />
        <link rel="canonical" href="https://doaguru.com/pr-service" />
      </Helmet>

      <Breadcrumb />
      <Wrapper>
        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgpr})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="hdd text-white">
                PR Marketing is essential for building Media Relationships
              </h1>

              <p className="pdd text-white mt-4">
                In the bustling landscape of today's business world, PR
                marketing stands as a cornerstone for fostering and nurturing
                robust media relationships. The interplay between public
                relations and media is not just a symbiotic connection but a
                vital avenue for brands to thrive and resonate with their
                audience. From traditional press releases to modern digital
                interactions, PR marketing serves as the conduit through which
                brands cultivate, sustain, and fortify meaningful relationships
                with the media.
              </p>
              <button
                type="button"
                className="btn mt-4 "
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12 mt-4">
              <img
                src={prhero}
                className="img3 d-block w-100"
                alt="..."
                id="pccenter"
              />
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12 mb-4 mt-5 ">
              <h2 style={{ textAlign: "center" }}>Importance of ppc</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              ></div>
              <p className="text-center">
                <div data-aos="fade-up" data-aos-duration="3000">
                  In a digital landscape inundated with content, PR Marketing
                  serves as the beacon that guides brands through the noise. It
                  encompasses strategic communications, relationship building,
                  and storytelling to engage the audience authentically. The
                  core objective? To cultivate a positive image, drive brand
                  awareness, and establish credibility.
                </div>
              </p>
            </div>
          </div>
          <div className="container">
            <div className="">
              <h5>Trends in PR Marketing for Media Relations</h5>
              <p>
                Think of trends in PR marketing for media relations as the new
                and exciting ways brands connect with the media. It's like
                discovering fresh paths to befriend journalists and influencers.
                From embracing digital platforms like podcasts and social media
                to teaming up with online personalities, these trends keep
                evolving. It's all about finding innovative ways to tell our
                story and get noticed in a crowded digital world.
              </p>
            </div>
            <div className="">
              <h5>Building Media Relationships</h5>
              <p>
                Building relationships with reporters, editors, and influencers
                is a bit like making new friends. Just like we learn about our
                friends' interests and what makes them tick, in PR marketing, we
                take the time to understand what matters to these media folks.
                When we know what they love and how they operate, we can share
                our stories in a way that lights up their enthusiasm. It's more
                than just firing off emails; it's about forging real connections
                that make our brand shine in their eyes, getting us that
                positive spotlight and visibility.
              </p>
            </div>
            <div className="">
              <h5>Nurturing Vital Media Relationships</h5>
              <p>
                In today's interconnected world, Public Relations (PR) Marketing
                stands as a linchpin in the realm of business strategy. In PR
                marketing, it's not just about promoting what we offer; it's
                about nurturing friendships with the media. Think of it like
                building strong bonds, just like we do with friends. These
                connections aren't just helpful; they're downright crucial for a
                company to thrive and succeed. Just like good friendships make
                life better, strong media relationships make our brand stand out
                and succeed in the long run.
              </p>
            </div>
            <div className="">
              <h6>
                Building Meaningful Connections: In PR marketing, it's like
                making friends with the media. Just as we understand our
                friends' likes and dislikes, we get to know journalists and
                influencers. By building trust and friendships, we can get them
                excited about our stories, which helps our brand shine in the
                news.
              </h6>
              <p>
                Telling Stories that Touch Hearts: PR marketing isn't just about
                sharing information; it's about storytelling.n PR marketing,
                it's akin to telling an incredible tale that grabs people's
                hearts, makes them smile or shed a tear, and sticks with them
                for a long time. These stories we share about our brand are like
                weaving a connection with our audience, almost like inviting
                them to be a part of our adventure. Just like how a memorable
                story bonds people, our brand's stories make our audience feel
                connected and engaged in our journey.
              </p>
              <p>
                Being the Guardian of Our Reputation: Think of PR marketing as
                protecting our brand's reputation. Just like we care about how
                others see us, we work hard to keep our brand image positive. We
                address problems openly, handle tough situations gracefully, and
                always strive to be honest. It's all about maintaining the trust
                people have in us.
              </p>
            </div>
          </div>
          <div className="container mb-4 mt-5">
            <h2 style={{ textAlign: "center" }}>
              Why Choose Doa Guru for PR Marketing Services?
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 20,
              }}
            ></div>
            <p className="text-center">
              <div data-aos="fade-up" data-aos-duration="3000">
                At Doa Guru, we excel in the art of Public Relations (PR)
                Marketing, offering comprehensive solutions tailored to elevate
                your brand's presence and resonance in the market. Here's why
                you should entrust us with your PR needs:
              </div>
            </p>
            <div className="">
              <h5>Expertise in Crafting Compelling Narratives</h5>
              <p>
                We understand the power of storytelling. Our team comprises
                seasoned professionals adept at creating narratives that
                captivate audiences, evoke emotions, and resonate with your
                brand's ethos.
              </p>
            </div>
            <div className="">
              <h5>Nurturing Meaningful Media Relationships</h5>
              <p>
                Building robust relationships with the media is at the core of
                effective PR Marketing. We cultivate these relationships,
                ensuring that your brand receives positive coverage, increased
                visibility, and credibility in the industry.
              </p>
            </div>
            <div className="">
              <h5>Strategic Brand Positioning</h5>
              <p>
                We don't just communicate; we strategically position your brand.
                Through targeted campaigns, innovative strategies, and
                leveraging various media channels, we ensure your brand stands
                out in a crowded marketplace.
              </p>
            </div>
            <div className="">
              <h5>Results-Driven Approach</h5>
              <p>
                Our focus is on delivering tangible results. We measure success
                through metrics, constantly refining strategies to optimize
                outcomes and drive your brand towards greater success.
              </p>
            </div>
            <div className="">
              <h5>Tailored Solutions for Every Client</h5>
              <p>
                We understand that each brand is unique. Our approach is highly
                personalized, crafting bespoke PR solutions aligned with your
                specific goals and values.
              </p>
            </div>
            <div className="">
              <h5>Commitment to Excellence</h5>
              <p>
                At Doa Guru, excellence is our standard. We're committed to
                delivering top-tier PR Marketing services that exceed
                expectations, ensuring your brand's success remains our primary
                objective.
              </p>
            </div>
            <div className="">
              <h4>
                Choose Doa Guru for PR Marketing that not only elevates your
                brand but also nurtures lasting connections and fosters a
                positive brand image in the market.
              </h4>
            </div>
          </div>
        </div>

        <div className="container" id="last">
          <div className="hero-section text-center">
            <h2 style={{ paddingTop: "2rem", color: "#155ea3" }}>
              Unlock Success with Our Cutting-Edge PR Marketing Solutions
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
              PR marketing revolves around strategically communicating your
              brand's story, values, and achievements to captivate your
              audience. It's not just about sending out press releases; it's a
              comprehensive strategy encompassing relationship-building,
              storytelling, and crisis management. To ensure your brand shines
              brightly, partner with a PR agency well-versed in the art of media
              relations and storytelling. Together, we'll elevate your brand's
              visibility, credibility, and resonance in the market.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Pr_Service;
const Wrapper = styled.div`
  #cont {
    height: 35rem;

    @media screen and (max-width: 768px) {
      height: 52rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 57rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      height: 30rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }

  #pccenter {
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      margin-top: -0.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 28rem;
      margin-top: 0rem;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      padding: 7rem;
      margin-top: -5rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }

  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;
