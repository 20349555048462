import React from "react";
import logodental from "../components/images/CRMLOGO.png";
import img1 from "../components/images/CRM/SuperAdmin.png";
import img2 from "../components/images/CRM/Admin.png";
import img3 from "../components/images/CRM/EmployeeD.png";
import img4 from "../components/images/CRM/SML.png";

import { Link } from "react-router-dom";
import styled from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import { Helmet } from "react-helmet";
import { MdDoubleArrow } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export function DGCrm() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <Finalnav />
      <Helmet>
        <title>
          Empower Your Dental Practice with Mini Dental Guru | Comprehensive Software for Dentists & Clinics
        </title>
        <meta
          name="description"
          content="Enhance efficiency and streamline operations in your dental practice with Mini Dental Guru. Our comprehensive software solution is designed to meet the unique needs of dental doctors and clinics, offering tools for appointment scheduling, patient management, billing, and more. Discover how Mini Dental Guru can revolutionize your practice today!
          "
        />
        <link rel="canonical" href="https://doaguru.com/dental-guru" />
      </Helmet>
      <div className="container-fluid product-page">
        <div className="logo-container ">

          <img src={logodental} alt="" className="w-25 m-2 rounded-2" />
          <div className="flex justify-content-end align-items-center">

            <h2 style={{ color: "red" }}>DG Customer Relationship Management</h2>
            <h1>
              We Care About Your Business<div className="underline"></div>
            </h1>
          </div>
        </div>

        <div className="container product-box my-5 p-2">
          <div className="row">
            <div className="col-lg-6 col-md-6 m-auto " id="mobile">
              {/* <img id="clientpages6" src={heroimg} className="" /> */}
              <div className="image-slider p-2">
                <Slider {...sliderSettings}>
                  <div><img src={img1} alt="Slide 1" /></div>
                  <div><img src={img2} alt="Slide 2" /></div>
                  <div><img src={img3} alt="Slide 3" /></div> 
                  <div><img src={img4} alt="Slide 4" /></div>
               
                </Slider>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 m-auto">
              <ul className=" d-flex flex-column gap-3 h5 mt-3">
                <li><MdDoubleArrow color="blue" /> Centralized Data Management</li>
                <li><MdDoubleArrow color="blue" /> Secure and Accessible</li>
                <li><MdDoubleArrow color="blue" /> Super Admin, Admin and Employee  Management</li>
                <li><MdDoubleArrow color="blue" /> Client Management</li>
                <li><MdDoubleArrow color="blue" /> User-friendly Interface</li>
                <li><MdDoubleArrow color="blue" /> Quotation Management</li>
                <li><MdDoubleArrow color="blue" /> Sales Automation</li>
                <li><MdDoubleArrow color="blue" /> Export Report</li>
                <li><MdDoubleArrow color="blue" /> Enhanced Analytics</li>
                {/* <li><MdDoubleArrow color="blue" /> Multi Branch Management</li> */}
              </ul>
            </div>
          </div>
          <div className="btnDiv ">

            <Link
              to="/contact_us"
              onClick={handleFooterLink}
              className="btn mt-4 mx-5 rounded-3 buttonBook "
              style={{ backgroundColor: "transperent", border: "solid blue " }}
              id="btn6"
            >
              Book Now
            </Link>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
            Experience the Power of Our Guru CRM (Customer Relationship Management) – Free Trial Demo
            </h2>
          </div>
        </div>
        <div className=" p-0 px-2">
          <div class="cta-section text-center my-5">
            <h2 style={{ color: "#00C7C7" }}>Discover More About Us</h2>
            <p>
            Not sure if the CRM (Customer Relationship Management) is the right fit for your business? No problem! 
      Try it out with a <strong>Free Trial Demo</strong> and see how it can revolutionize the way you create and CRM (Customer Relationship Management). 
      Get hands-on experience with all the features, and see how this tool can streamline your workflow.
            </p>
            <a href="/contact_us" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
              Contact Us
            </a>
          </div>
        </div>
        <div className="product-box">
          <section id="quotation-system">
            <div class="container">
            <h1>Guru CRM: A Comprehensive Guide</h1>

<h2>1. What is CRM?</h2>
<p>
    CRM (Customer Relationship Management) is a strategy used by businesses to manage interactions with current and potential customers. It involves using technology to organize, automate, and synchronize sales, marketing, customer service, and technical support.
</p>

<h2>2. Why Use a CRM?</h2>
<ul>
    <li><strong>Centralized Information:</strong> Store customer information in one place.</li>
    <li><strong>Improved Communication:</strong> Better service and avoid miscommunication.</li>
    <li><strong>Enhanced Customer Experience:</strong> Tailor offerings based on customer history.</li>
    <li><strong>Increased Sales:</strong> Identify potential sales opportunities.</li>
    <li><strong>Data Analysis:</strong> Make data-driven decisions using analytics tools.</li>
</ul>

<h2>3. Key Features of Guru CRM</h2>
<ul>
    <li><strong>Contact Management:</strong> Manage customer contact details and communication history.</li>
    <li><strong>Lead Management:</strong> Track leads through the sales pipeline.</li>
    <li><strong>Sales Automation:</strong> Automate repetitive tasks to focus on closing deals.</li>
    <li><strong>Reporting and Analytics:</strong> Generate reports to analyze performance.</li>
    <li><strong>Task Management:</strong> Create and assign tasks within the CRM.</li>
    <li><strong>WhatsApp Integration:</strong> Automatic WhatsApp notification to employees upon lead assignment. </li>
    <li><strong>Customizable Dashboards:</strong> Create dashboards for key metrics.</li>
    <li><strong>Mobile Access:</strong> Access data on the go through mobile apps.</li>
    <li><strong>Integration with Other Tools:</strong> Streamline processes with other software.</li>
    <li><strong>Customer Support Features:</strong> Ticketing systems and knowledge bases.</li>
</ul>

<h2>4. Benefits of Using Guru CRM</h2>
<ul>
    <li><strong>Enhanced Collaboration:</strong> Share information easily among team members.</li>
    <li><strong>Improved Customer Insights:</strong> Understand customer needs better.</li>
    <li><strong>Increased Efficiency:</strong> Save time by automating processes.</li>
    <li><strong>Scalability:</strong> Accommodate growing data and user requirements.</li>
</ul>

<h2>5. How to Get Started with Guru CRM</h2>
<ol>
    <li>Setup and Configuration: Adjust CRM settings to meet your requirements.</li>
    <li>Registration: The Super Admin registers Admin and Employee users.</li>
    <li>User Training: Train users for effective and efficient CRM utilization.</li>
    <li>Regular Maintenance: Keep the CRM updated and ensure data accuracy.</li>
</ol>

<h2>6. Conclusion</h2>
<p>
    Guru CRM offers a powerful solution for managing customer relationships and streamlining business processes. With its robust features, businesses can enhance communication, improve sales performance, and deliver exceptional customer service. Implementing a CRM like Guru CRM can lead to significant growth and success in today’s competitive market.
</p>

<h2>Next Steps</h2>
<ul>
    <li><strong>Explore Demo:</strong> Schedule a demo to see how Guru CRM can work for your business.</li>
    <li><strong>Contact Sales:</strong> Reach out for pricing information and tailored solutions.</li>
    <li><strong>Customer Support:</strong> Access resources and support for any questions.</li>
</ul>
</div>
          </section>

          <div className="btnDiv ">

            <Link
              to="/contact_us"
              onClick={handleFooterLink}
              id="btn6"
            ><button className="btn mt-4 mx-5 rounded-3 buttonBook " style={{ backgroundColor: "transperent", border: "solid blue " }}>
                Book Now
              </button></Link>

            <h2 className="mt-4 font-weight-bold" style={{ color: "#002c63" }}>
            Experience the Power of Our Guru Customer Relationship Management – Free Trial Demo
            </h2>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.div`

.product-page{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

ul {
list-style: none;
}
  .underline {
    height: 4px;
    width: 38rem;
    background-color: #4cd3d9;
    margin-top: 2;
    margin-bottom: 10;
    @media screen and (max-width: 768px) {
      width: 22rem;
    }
  }

  .logo-container {
      display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image1{
  display: flex;
  justify-content: center;
  width: 25rem;
  }
  .product-box{
    border: solid;
    border-radius: 2.5rem;
    border-color: cadetblue;
    margin: 1rem;
    padding: 3rem;
  }
.buttonBook:hover {
  background-color: blue;
  color: white;
  font-weight: bold;
}
    .image-slider img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }


`;
