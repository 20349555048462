import React from "react";
import styled from "styled-components";
import Finalnav from "../../page/Homepages/Finalnav";
import bggraphic from "../images/bggraphic.webp";
import graphichero from "../images/ggg.webp";
import { Link } from "react-router-dom";
import graphicrole from "../images/graphicrole.webp";
import graphicdesign from "../images/graphicdesign.webp";
import graphicbg from "../images/graphic121.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";

function Graphic() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>Best Graphic Designing Company - DOAGuru</title>
        <meta
          name="description"
          content="Unlock the Power of Visual Storytelling with the Best Graphic Design Company. Let Us Transform Your Ideas into Compelling Visual Narratives for Success.
          "
        />
        <link rel="canonical" href="https://doaguru.com/graphic-designing" />
      </Helmet>
      <Wrapper>
        <Breadcrumb />

        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bggraphic})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="hdd text-white">Best Graphic Designing Company</h1>

              <p className="pdd text-white mt-4">
                At DOAGuru Infosystems we go beyond the ordinary by providing
                visionary design solutions that redefine excellence in Jabalpur.
                Our team of innovative professionals carefully crafts
                captivating narratives that truly reflect the essence of your
                brand. With a dedication, to precision and uniqueness we bring
                concepts to life. Deliver designs that captivate and inspire.
                Enhance your brand's identity, with the expertise of Jabalpur's
                Best graphic design company, where every pixel tells a
                compelling story of your success.
              </p>
              <button
                type="button"
                className="btn mt-4 "
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src={graphichero}
                className="img3 d-block w-100  "
                alt="..."
                id="graphero"
              />
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="hdd text-center">
            <h2>The Role of Graphic Design</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 20,
              }}
            ></div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-12 " id="mobile">
              <div data-aos="fade-left">
                <img id="clientp" src={graphicrole} className="img-fluid" />
              </div>
            </div>

            <div
              className="col-lg-6 col-md-12"
              id="respon"
              style={{ marginTop: "2em" }}
            >
              <div data-aos="fade-right">
                <h4>Key Components of Graphic Design</h4>
                <p>
                  By bridging the chasm between creativity and communication,
                  Graphic Design Services, in collaboration with the expertise
                  of the Best IT Company, assume a central role across an array
                  of industries. It encompasses the judicious application of
                  visual constituents to notions, data, engagement, and
                  stimulation.
                </p>

                <ul className="list">
                  <li>Typography</li>

                  <li>Layout and Composition</li>

                  <li>Colour Theory</li>

                  <li>Imagery and Illustration</li>

                  <li>Branding and Identity</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6" id="desktop">
              <div data-aos="fade-left">
                <img id="clientp" src={graphicrole} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="heading_role  text-center mt-4">
            <h2>Design process</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 20,
              }}
            ></div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div data-aos="fade-right">
                {" "}
                <img
                  id="clientp"
                  src={graphicdesign}
                  className="img-fluid"
                  style={{ marginTop: "-1em" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12" id="role">
              <div data-aos="fade-left">
                <p>
                  The Design Process represents a systematic approach to
                  ideation and problem-solving. It is a methodology adaptable to
                  an array of quandaries, spanning from small-scale products to
                  intricate services. Embarking on a design odyssey with our
                  Graphic Design Company, in partnership with the finest digital
                  marketing company, involves the transformation of your
                  concepts into tangible, visually arresting masterpieces. Our
                  journey begins by comprehensively grasping your objectives and
                  vision.
                </p>
                <ul className="list">
                  <li>Discovery</li>
                  <li>Conceptualization</li>
                  <li>Collaboration</li>
                  <li>Refinement</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid text-center  mt-2"
          style={{
            backgroundImage: `url(${graphicbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          id="grap"
        >
          <div className="row">
            <div className="col-sm-12" style={{ marginTop: "4.5rem" }}>
              <h2>Graphic Designer in Jabalpur</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              ></div>
              <p>
                {" "}
                Logo Design | Logo Animation Video | Customized Video |
                Stationery Design | Brochure Design | Flyer Design | Leaflet
                Design | Billboard Design | Poster Design | Banner Design |
                Festival Greeting Design | Digital Business Card Design | Motion
                Graphics | Website Designing{" "}
              </p>

              <p>
                Graphic Designer in Jabalpur | Video Ad Creator in Jabalpur |
                Logo Maker in Jabalpur
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 mb-2 text-center">
              <h2>Services</h2>
              <div
                className="underline mx-auto"
                style={{
                  height: 3,
                  width: "4rem",
                  backgroundColor: "#34495E",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              ></div>

              <h5>
                As a Graphic Design firm, we offer an array of design services
                meticulously tailored to your distinct requisites.
              </h5>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card shadow">
                <div className="card body" id="cardres">
                  <h4 className=" logo text-center">Logo Design</h4>

                  <h6 style={{ textAlign: "center", margin: 25 }}>
                    Elevating Your Brand's Distinctiveness!
                  </h6>
                  <div className="underline"></div>
                  <p style={{ textAlign: "center", padding: "15px" }}>
                    The cornerstone of your brand identity lies in your logo.
                    Our Graphic Design Company specializes in crafting logos
                    that seamlessly fuse creativity and strategy to encapsulate
                    your brand's essence flawlessly. Every facet, from vibrant
                    hues to sophisticated design elements, is intricately
                    fashioned to ensure a unique and memorable representation of
                    your enterprise.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3 ">
              <div className="card shadow">
                <div className="card body" id="cardres">
                  <h4 className="logo text-center">Branding</h4>
                  <h6 style={{ textAlign: "center", margin: 25 }}>
                    Shaping Your Unparalleled Persona!
                  </h6>
                  <div className="underline"></div>
                  <p style={{ textAlign: "center", padding: "15px" }}>
                    Beyond a mere logo, Graphic Design Services, in synergy with
                    a top-tier social media marketing agency, seamlessly infuses
                    branding expertise into the essence of your business. We
                    excel in crafting recognizable visual identities that
                    resonate with your target audience, capturing the essence
                    and values of your brand.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card shadow">
                <div className="card body" id="cardres">
                  <h4 className="logo text-center">Web Design</h4>
                  <h6 style={{ textAlign: "center", margin: 25 }}>
                    Crafting Digital Journeys that Ignite Inspiration!
                  </h6>
                  <div className="underline"></div>
                  <p style={{ textAlign: "center", padding: "15px" }}>
                    In the contemporary digital era, your online presence stands
                    as a linchpin. Our unified Graphic Design and Website
                    Development company specializes in sculpting websites that
                    exude captivation and engagement, prioritizing user
                    experience and aesthetics.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card shadow">
                <div className="card body" id="cardres">
                  <h4 className="logo text-center">Print Materials</h4>

                  <h6 style={{ textAlign: "center", margin: 25 }}>
                    Breathing Life into Ideas with Tangible Designs!
                  </h6>
                  <div className="underline"></div>
                  <p style={{ textAlign: "center", padding: "15px" }}>
                    Print media possesses a distinct allure and tactile allure.
                    We possess expertise in transmuting your concepts into
                    tangible artifacts such as business cards, brochures,
                    posters,and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="last">
          <div className="hero-section text-center text-black mt-5">
            <h2 style={{ paddingTop: "3rem" }}>Let's Manifest Your Vision</h2>

            <p className="mt-4">
              Are you primed to transform your notions into visually stunning
              realizations? Whether you seek to revamp your brand identity,
              craft eye-catching marketing collateral, or construct an immersive
              website, I stand ready to assist. As a results-driven ensemble,
              comprising the preeminent IT establishment and the finest digital
              marketing agency, we bring a unique amalgamation of technical
              acumen and creative finesse to the fore. Let us collaborate to
              breathe life into your vision.
            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Graphic;

const Wrapper = styled.div`
  .heading h1 {
    display: flex;
    padding: 30px;
    justify-content: center;
    font-size: 40px;
  }

  .img2 {
    border-radius: 20px;
    box-shadow: 6px 6px 4px 1px #888888;
    width: 100%;
    height: 300px;
  }
  .col-6 {
    padding: 20px;
  }
  #cont {
    height: 36rem;

    @media screen and (max-width: 768px) {
      height: 57rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 53rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  #respon {
    margin-top: 6rem;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
  }
  #clientp {
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 2rem;
      margin-left: 3.5rem;
    }
  }

  #role {
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }
  .logo {
    margin-top: 1rem;
    color: #0084b5;
  }
  #grap {
    height: 20rem;
    @media screen and (max-width: 768px) {
      height: 27rem;
    }
  }
  .list {
    font-weight: 600;
    line-height: 1.9;
  }

  #graphero {
    margin-top: 5rem;
    margin-left: 0rem;
    @media screen and (max-width: 768px) {
      margin-top: 3rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 1rem;
      height: 30rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 25rem;
      margin-top: 0rem;
    }
  }
  #cardres {
    height: 20rem;
    @media screen and (max-width: 768px) {
      height: 25rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 27rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }
  .hdd {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  .hdd1 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;
