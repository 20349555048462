import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import backimg from "../../components/images/1.png";

const HomeContent = () => {
    const handleFooterLink = () => {
        window.scrollTo(0, 0);
      };
  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="text">
                    <h1>Best Digital Marketing Company And IT Solutions!</h1>

                    <p>Welcome to DOAGuru Infosystems, where we provide expertise and IT solutions. As the Best Digital Marketing Company, in Jabalpur, we lead the way by offering customized marketing strategies to enhance your presence. Our skilled team develops solutions using advanced technology to drive your business towards success. With a focus on accuracy, creativity, and unwavering commitment we redefine what it means to excel in the industry ensuring that your brand stands out in the landscape. Discover the transformation experience, with DOAGuru Infosystems—the industry's leading name.</p>

                    <Link to="/about_us" onClick={handleFooterLink} id="aboutus">
        
            Know About Us
          </Link>
                </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default HomeContent;
const Wrapper = styled.div`
/* background-image: url(${backimg});
background-size: cover;
background-position: right;
background-repeat: no-repeat; */
background: #002c63;
.text{
    margin: 5rem;
    @media screen and (min-width: 280px) and (max-width: 768px) {
      margin: 2rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      margin: 2rem;
    }
}
.text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    margin: 0;
    }
  }
  .text p {
    font-size: 1.2rem;
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 42rem;
    }
  }
  #aboutus {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(to right, #00e5e5, #86ff68);
    color: #002c63;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
    /* &:hover {
    background-color:  #ff7d7d;
    color: white; */
  }
  `;
