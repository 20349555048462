import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import emailjs from "@emailjs/browser";
import axios from 'axios';
import cogoToast from 'cogo-toast';

function Connect() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uojo2hs",
        "template_nuatms6",
        form.current,
        "DLd267MmQhuqWMkKz"
      )
      .then(
        (result) => {
          console.log(result.text); 
          alert("message sent successflly");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const[values,setValues]=useState({
    name:'',
    phone_no:'',
    email_id:'',
    message:''
  })
  const handleChange=(event)=>{
    setValues({...values,[event.target.name]:[event.target.value]})
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://doaguru.com/userdata", values)
      .then((res) => {
        cogoToast.success('Message sent successsfully');
        // Clear the input fields
        setValues({
          name: "",
          phone_no: "",
          email_id: "",
          message: "",
        });
      })
      .catch((err) => cogoToast.error('Something went wrong'));
  };
  return (
    <Wrapper>
      <div className="container mt-5">
        <div className="row" id="design">
          <div className="col-sm-6">
            <h2>Connect with us to Discuss More</h2>
            <hr className="h" />
            <h4>We’d love to help & work with you</h4>

            <p>
              We’re always ready to listen to the needs of both new and existing
              clients and work hard to provide you with a solution that
              surpasses your expectations.
            </p>
          </div>
          <div className="col-sm-6">
          <form
              
              onSubmit={handleSubmit}    
              className="mb-1 rounded-1 p-4 border bg-primary"
            >
              <label className="d-block mb-4 text-white">
                <span className="form-label d-block">Your name</span>
                <input
                  required
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder=" Enter Your Name"
                  value={values.name}
                  onChange={handleChange}
                />
              </label>
              
              <label className="d-block mb-4 text-white">
                <span className="form-label d-block">Phone Number</span>
                <input
                  required
                  name="phone_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Moblie Number"
                  value={values.phone_no}
                  onChange={handleChange}
                  maxLength={10}
                />
                <span></span>
              </label>

              <label className="d-block mb-4 text-white">
                <span className="form-label d-block">Email address</span>
                <input
                  required
                  name="email_id"
                  type="email"
                  className="form-control"
                  placeholder="Enter Your Email Id"
                  value={values.email_id}
                  onChange={handleChange}
                />
              </label>

              <label className="d-block mb-4">
                <span className="form-label d-block text-white">Message</span>
                <textarea
                  name="message"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  value={values.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </label>

              <div className="mb-3 text-white">
                <button type="submit" className="btn  px-3 rounded-3 ">
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Connect;
const Wrapper = styled.div`
  button {
    background-color: white;
  }
  button:hover {
    background-color: #4caf50;
    color: white;
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
   
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size:1.2rem;
   
    }
  }
`;
